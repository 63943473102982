.MediaInDetails{
position: absolute;
width: 22.813em;
top: 0;
bottom: 0;
background: #FFFFFF;
.tabs{
    width: 100%;
    display: flex;
    justify-content: space-between;
    li{
        padding: 0 0.625em 0.625em;
        font: normal normal 1rem/120% $semibold;
        letter-spacing: 0px;
        color: #FFFFFF;
        cursor: pointer;
        border-bottom: 0.25em solid transparent;
        &.active{
            border-bottom-color: #fff;
        }
    }
}
.contactinfo-header{
    padding-bottom: 0 !important;
    align-items: flex-end !important;
    height: 5.5625em !important;
    .backtoInfo{
        position: absolute;
        left: 0.5625em;
        top: 1.3em;
    }

}
.MediaDetails{
width: 100%;
height: calc(var(--maxParentheight) - 5.5625em);
overflow-y: auto;
display: flex;
justify-content: center;
align-items: center;
color: #767676;
font: normal normal 0.875rem/120% $medium;

.MediaImage{
    width: 100%;
    height: 100%;
    padding: 1.5625em;
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 33.33%;
            padding: 0.25em;            
        }
    }
}
}
}