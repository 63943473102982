@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Bold.woff') format('woff'), url('../fonts/ProximaNova-Bold.ttf')  format('truetype'), url('../fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Light.woff') format('woff'), url('../fonts/ProximaNova-Light.ttf')  format('truetype'), url('../fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova-Regular.ttf')  format('truetype'), url('../fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ProximaNova-Semibold.woff') format('woff'), url('../fonts/ProximaNova-Semibold.ttf')  format('truetype'), url('../fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'SFUIDisplayBlack';
    src: 
    url('../fonts/SFUIDisplay-Black.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Black.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Black.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Black.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Black.svg#SFUIDisplay-Black') format('svg');
    
  }

@font-face {
    font-family: 'SFUIDisplayBold';
    src: 
    url('../fonts/SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Bold.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Bold.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Bold.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Bold.svg#SFUIDisplay-Bold') format('svg');
    
  }

@font-face {
    font-family: 'SFUIDisplayHeavy';
    src: 
    url('../fonts/SFUIDisplay-Heavy.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Heavy.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Heavy.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Heavy.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Heavy.svg#SFUIDisplay-Heavy') format('svg');
    
  }

@font-face {
    font-family: 'SFUIDisplayMedium';
    src: 
    url('../fonts/SFUIDisplay-Medium.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Medium.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Medium.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Medium.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Medium.svg#SFUIDisplay-Medium') format('svg');
    
  }

@font-face {
    font-family: 'SFUIDisplayRegular';
    src: 
    url('../fonts/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Regular.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Regular.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Regular.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Regular.svg#SFUIDisplay-Regular') format('svg');

  }

@font-face {
    font-family: 'SFUIDisplaySemibold';
    src: 
    url('../fonts/SFUIDisplay-Semibold.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/SFUIDisplay-Semibold.otf')  format('opentype'),
    url('../fonts/SFUIDisplay-Semibold.woff') format('woff'), 
    url('../fonts/SFUIDisplay-Semibold.ttf')  format('truetype'), 
    url('../fonts/SFUIDisplay-Semibold.svg#SFUIDisplay-Semibold') format('svg');
  }

  @font-face {
    font-family: 'SFUIDisplayLight';
    src: url('../fonts/SFUIDisplayLight.eot');
    src: url('../fonts/SFUIDisplayLight.eot') format('embedded-opentype'),
         url('../fonts/SFUIDisplayLight.woff2') format('woff2'),
         url('../fonts/SFUIDisplayLight.woff') format('woff'),
         url('../fonts/SFUIDisplayLight.ttf') format('truetype'),
         url('../fonts/SFUIDisplayLight.svg#SFUIDisplayLight') format('svg');
  }

  @font-face {
    font-family: "SF-Pro-Text-Bold";
    src: url('../fonts/new/SF-Pro-Text-Bold.woff') format('woff'),
    url('../fonts/new/SF-Pro-Text-Bold.woff2') format('woff2'),
    url('../fonts/new/SF-Pro-Text-Bold.svg#SF-Pro-Text-Bold') format('svg'),
    url('../fonts/new/SF-Pro-Text-Bold.eot'),
    url('../fonts/new/SF-Pro-Text-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/new/SF-Pro-Text-Bold.otf') format('truetype');
  }

  @font-face {
    font-family: "SF-Pro-Text-BoldItalic";
    src: url('../fonts/new/SF-Pro-Text-BoldItalic.woff') format('woff'),
    url('../fonts/new/SF-Pro-Text-BoldItalic.woff2') format('woff2'),
    url('../fonts/new/SF-Pro-Text-BoldItalic.svg#SF-Pro-Text-BoldItalic') format('svg'),
    url('../fonts/new/SF-Pro-Text-BoldItalic.eot'),
    url('../fonts/new/SF-Pro-Text-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/new/SF-Pro-Text-BoldItalic.otf') format('truetype');
  }

  @font-face {
    font-family: "SF-Pro-Text-Medium";
    src: url('../fonts/new/SF-Pro-Text-Medium.woff') format('woff'),
    url('../fonts/new/SF-Pro-Text-Medium.woff2') format('woff2'),
    url('../fonts/new/SF-Pro-Text-Medium.svg#SF-Pro-Text-Medium') format('svg'),
    url('../fonts/new/SF-Pro-Text-Medium.eot'),
    url('../fonts/new/SF-Pro-Text-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/new/SF-Pro-Text-Medium.otf') format('truetype');
}
@font-face {
  font-family: "SF-Pro-Text-MediumItalic";
  src: url('../fonts/new/SF-Pro-Text-MediumItalic.woff') format('woff'),
  url('../fonts/new/SF-Pro-Text-MediumItalic.woff2') format('woff2'),
  url('../fonts/new/SF-Pro-Text-MediumItalic.svg#SF-Pro-Text-MediumItalic') format('svg'),
  url('../fonts/new/SF-Pro-Text-MediumItalic.eot'),
  url('../fonts/new/SF-Pro-Text-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/new/SF-Pro-Text-MediumItalic.otf') format('truetype');
}

@font-face {
  font-family: "SFPRODISPLAYREGULAR";
  src: url('../fonts/new/SFPRODISPLAYREGULAR.woff') format('woff'),
  url('../fonts/new/SFPRODISPLAYREGULAR.woff2') format('woff2'),
  url('../fonts/new/SFPRODISPLAYREGULAR.eot'),
  url('../fonts/new/SFPRODISPLAYREGULAR.eot?#iefix') format('embedded-opentype'),
  url('../fonts/new/SFPRODISPLAYREGULAR.otf') format('truetype');
}




@font-face {
  font-family:"museo-sans";
  src:url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-sans";
  src:url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-sans";
  src:url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-sans";
  src:url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-sans";
  src:url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-sans";
  src:url("https://use.typekit.net/af/2841b6/00000000000000000000e806/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/2841b6/00000000000000000000e806/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/2841b6/00000000000000000000e806/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-slab";
  src:url("https://use.typekit.net/af/aa4f4e/000000000000000000012043/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/aa4f4e/000000000000000000012043/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/aa4f4e/000000000000000000012043/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-slab";
  src:url("https://use.typekit.net/af/f1892e/00000000000000000000ec08/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/f1892e/00000000000000000000ec08/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/f1892e/00000000000000000000ec08/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-slab";
  src:url("https://use.typekit.net/af/c225e2/000000000000000000011aff/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/c225e2/000000000000000000011aff/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/c225e2/000000000000000000011aff/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }
  
  @font-face {
  font-family:"museo-slab";
  src:url("https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
  }