// http://meyerweb.com/eric/tools/css/reset/
//  v2.0 | 20110126
//  License: none (public domain)

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
*,
::after,
::before {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
body {
  line-height: normal;
  box-sizing: border-box;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img{
  // height: 100%;
  // width: 100%;
  // cursor: pointer;
  max-width: 100%;
}
svg{
  height: 100%;
  width: 100%;
}
i{
  display: inline-block;
  cursor: pointer;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#BBC1C3;
  font-size: 14px;
}
textarea:focus, input:focus{
  outline: none;
}
input:focus{
  outline: none;
  background-color: transparent;
}

select {
  -webkit-appearance: none;
   margin: 0;
   border-radius: 0;
}
input{
     appearance: none;
     border-radius: 0;
}